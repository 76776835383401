import React, { useState } from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useSelector } from 'react-redux';
import { Flex, Image, Modal } from 'antd';
import styled from 'styled-components';
import { ListAlt, Payment } from '@mui/icons-material';
import axios from 'axios';
import { RootState } from '../../store';
import { OrderDetailType } from '../../../../common/types';
import { colors } from '../../assets/constStyles';
import { paths } from '../../consts';

interface IAmount {
  amount: string
}

const PStyled = styled.p`
  font-size: 1.3em;
  text-transform: uppercase;
  font-weight: bolder;
`;

const SpanStyled = styled.span`
  font-weight: bold;
  color: ${colors.colorTextLight}
`;

const FlexOrderStyled = styled(Flex)`
  border: 1px solid #DBDBDB;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
`;

export const PayPalButton2: React.FC<IAmount> = ({ amount }) => {
  const [showCompleteOrderModal, setShowCompleteOrderModal] = useState(false);
  const orderDetail = useSelector<RootState, OrderDetailType>((state) => state.oldMap.orderDetail);

  const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!,
    'disable-funding': '',
    components: 'buttons',
    //    locale: 'en_US',
  };

  const createOrder = (data: any, actions: any) => actions.order.create({
    intent: 'CAPTURE',
    purchase_units: [{
      amount: {
        value: amount,
        currency_code: 'USD',
      },
    }],
    payer: {
      name: {
        given_name: orderDetail.userData.firstName,
        surname: orderDetail.userData.lastName,
      },
      email_address: orderDetail.userData.email,
      address: {
        address_line_1: orderDetail.userData.street,
        admin_area_2: orderDetail.userData.city,
        admin_area_1: orderDetail.userData.state,
        postal_code: orderDetail.userData.postalCode,
        country_code: 'US',
      },
    },
  });

  const onApprove = async (data: any, actions: any) => {
    if (!actions.order) {
      console.error('No order action available');
      return Promise.resolve();
    }

    try {
      const details: any = await actions.order.capture();
      const payerName = details.payer?.name?.given_name || 'Customer';

      console.log(`Transaction completed by ${payerName}`, details);

      if (!orderDetail || Object.keys(orderDetail).length === 0) {
        console.error('orderDetail is empty or undefined');
        return await Promise.resolve();
      }

      const result = (await axios.post(`${process.env.REACT_APP_REST_API}${paths.ORDER}`, orderDetail)).data;
      console.log(result);

      setShowCompleteOrderModal(true);
      return await Promise.resolve();
    } catch (err) {
      console.error('Error capturing PayPal order:', err);
      // TODO написать вывод сообщения пользоватедю что чтото пошло не так, попробуйте позже
      return Promise.reject(err);
    }
  };

  const onError = (err: any) => {
    console.error('Error capturing PayPal order:', err);
  };

  const handleCancel = () => {
    setShowCompleteOrderModal(false);
    window.location.href = '/';
  };

  const handleOk = () => {
    setShowCompleteOrderModal(false);
    window.location.href = '/';
  };

  return (<>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={{ layout: 'vertical' }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                />
            </PayPalScriptProvider>
            <Modal
                open={showCompleteOrderModal}
                title="Payment confirmation"
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ size: 'large', style: { backgroundColor: colors.colorGreen2, width: '90px' } }}
                cancelButtonProps={{ size: 'large', className: 'custom-cancel-btn' }}
                footer={(_, { OkBtn }) => (
                    <>
                        {/* <CancelBtn/> */}
                        <OkBtn/>
                    </>
                )}
            >
                <div>
                    <Flex vertical align={'center'} style={{ marginBottom: '0.7em' }}>
                        <Image src={'./img/confirmOrder.png'} width={200} height={200} preview={false}></Image>
                        <h2>Your order is made!</h2>
                        <p>Congratulations! Your order has been successfully proceed, we will pick up your order as soon as possible!</p>
                    </Flex>
                    <div style={{ backgroundColor: '#F6F6F6', padding: '20px', borderRadius: 10 }}>
                        <Flex style={{ alignItems: 'center', gap: 10 }}>
                            <Image src={'./img/orderInfo.png'} width={60} height={60} preview={false}></Image>
                            <PStyled>Order # {orderDetail.id}</PStyled>
                        </Flex>
                        <Flex vertical gap={20}>
                            <FlexOrderStyled vertical>
                                <Flex align={'center'} gap={4} style={{ marginBottom: 5 }}>
                                    <ListAlt fontSize={'small'}/> <h3 style={{ fontWeight: 'bolder' }}>Delivery summary</h3>
                                </Flex>
                                    <p><SpanStyled>Full Name </SpanStyled> {`${orderDetail.userData.firstName} ${orderDetail.userData.lastName}`}</p>
                                    <p><SpanStyled>Address </SpanStyled>{`${orderDetail.userData.street}, ${orderDetail.userData.cityState}, ${orderDetail.userData.postalCode}`}</p>
                                    <p><SpanStyled>Phone number </SpanStyled>{orderDetail.userData.phone}</p>
                            </FlexOrderStyled>
                            <FlexOrderStyled vertical >
                                <Flex align={'center'} gap={4} style={{ marginBottom: 5 }}>
                                    <Payment fontSize={'small'}/><h3 style={{ fontWeight: 'bolder' }}>Payment summary</h3>
                                </Flex>
                                <p><SpanStyled>Total items </SpanStyled> {Object.keys(orderDetail.items).length}</p>
                                <p><SpanStyled>Total cost </SpanStyled> {`$${orderDetail.finalPrice}`}</p>
                            </FlexOrderStyled>
                        </Flex>
                    </div>
                </div>
            </Modal>
        </>
  );
};
