import React, { useEffect, useRef } from 'react';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { XYZ } from 'ol/source';
import { getCenter } from 'ol/extent';
import { createXYZ } from 'ol/tilegrid';
import styled from 'styled-components';
import { defaults as defaultControls, Zoom } from 'ol/control';
import { OldMapItemType } from '../../../common/types';

interface IOpenLayerMap{
  image: OldMapItemType
}

const DivOLStyled = styled.div`
  .ol-control {
    position: absolute;
  }

  .ol-control button{
    width: 30px;
    height: 30px;
    background-color: aliceblue;
    border: 1px solid grey;
    font-size: 18px;
  }
  
  .ol-zoom {
    top: auto;
    bottom: 10px;
    left: 10px;
  }

  .ol-scale-line {
    top: auto;
    bottom: 10px;
    right: 10px;
  }
`;

export const OpenLayerMap: React.FC<IOpenLayerMap> = ({ image }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [baseUrl] = image.link.split('/out');

  // const extent = [0, 0, Math.min(image.sizePx.width, image.sizePx.height), Math.min(image.sizePx.height, image.sizePx.width)]; // [xmin, ymin, xmax, ymax]
  const extent = [0, 0, 10000, 10000]; // [xmin, ymin, xmax, ymax]
  const extent2 = [0, 0, 0, 0]; // [xmin, ymin, xmax, ymax]

  useEffect(() => {
    const tileGrid = createXYZ({
      extent,
      tileSize: 256,
      maxZoom: 7,
    });

    const map = new Map({
      target: mapContainer.current || '',
      layers: [
        new TileLayer({
          source: new XYZ({
            // url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
            url: `${process.env.REACT_APP_S3_STORAGE_URL_COMMON}${process.env.REACT_APP_BUCKET_COMMON_IMAGE}${baseUrl}/out/{z}/{x}/{-y}.png`,
            tileGrid,
          }),
        }),
      ],
      view: new View({
        center: getCenter(extent2),
        zoom: 1,
        extent,
      }),
      controls: defaultControls({
        rotate: false,
      }).extend([
        new Zoom({
          target: 'zoom-controls',
        }),
      ]),
    });

    return () => map.setTarget('');
  }, [image]);

  return (
    <DivOLStyled
      ref={mapContainer}
      style={{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
      }}
    ></DivOLStyled>
  );
};
