import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import React from 'react';
import { paths } from '../../consts';

interface IButtonStore {
  buttonText: string
}
export const ButtonToStore: React.FC<IButtonStore> = ({ buttonText }) => (
    <motion.div
        initial={{ backgroundColor: '#387C35' }}
        animate={{ backgroundColor: ['#387C35', '#66bb6a', '#387C35'] }}
        transition={{ duration: 2, repeat: Infinity, repeatType: 'reverse' }}
        style={{ display: 'inline-block', borderRadius: '4px' }}
    >
        <Link to={paths.STORE} style={{ textDecoration: 'none' }}>
            <Button size={'large'}
                    variant="contained"
                    style={{ backgroundColor: 'transparent', color: '#fff' }}
            >
                {buttonText}
            </Button>
        </Link>
    </motion.div>
);
