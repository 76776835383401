import React, { useCallback, useMemo, useState } from 'react';
import {
  Col, Flex, Form, Input, notification, Row, Spin,
} from 'antd';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import { Button } from '@mui/material';
import axios from 'axios';
import { NotificationFilled } from '@ant-design/icons';
import { H1Styled, H4Styled } from '../ContentHeader';
import { DivMinWidth } from '../../App';
import { ContactUsType } from '../../types/types';
import { ContactUsLocation } from './ContactUsLocation';
import { paths } from '../../consts';
import { useAlertMessage } from '../hook/useAlertMessage';

const RowStyled = styled(Row)`
  margin: 0 auto;
  display: flex;
  `;

const ColStyled = styled(Col)`
  display: flex;
  `;

const FlexStyled = styled(Flex)`
  margin: 0;
`;

const FlexMainStyled = styled(Flex)`
  width: 100%;
  padding: 100px 40px 40px 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Context = React.createContext({ name: 'Default' });

export const ContactUs: React.FC = () => {
  const [spinModalRegistration, setSpinModalRegistration] = useState(false);
  const [msgRegistration, setMsgRegistration] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const initialValues: ContactUsType = {
    yourName: '', email: '', message: '',
  };

  const openNotification = useCallback((text: string) => {
    api.info({
      message: 'Request process',
      description: <Context.Consumer>{() => `${text}!`}</Context.Consumer>,
      icon: <NotificationFilled style={{ color: '#108ee9' }}/>,
    });
  }, []);

  const onFinish = useCallback(async (values: any) => {
    setSpinModalRegistration(true);
    setMsgRegistration('');
    try {
      const result = (await axios.post(`${process.env.REACT_APP_REST_API}${paths.USER_REQUEST}`, values)).data;

      form.resetFields();

      openNotification(result.message);
    } catch (e: any) {
      setMsgRegistration(e.response.data.message);
    }
    setSpinModalRegistration(false);
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const renderMessageErrorRegistration = useAlertMessage('error', msgRegistration);

  const contextValue = useMemo(() => ({ name: 'Oldcharts' }), []);

  return (
    <div id="contact">
        <DivMinWidth>
            <Context.Provider value={contextValue}>
                {contextHolder}

                <RowStyled>
                <ColStyled xs={24} sm={24} md={12} lg={12} xl={12}>
                    <FlexMainStyled vertical>
                        <Flex vertical style={{ width: '100%' }}>
                            <H4Styled>Get in touch</H4Styled>
                            <H1Styled>Reach out to us</H1Styled>
                        </Flex>
                        <Spin spinning={spinModalRegistration} tip={'Please wait'} style={{ width: '100%' }}>
                    <Flex style={{ paddingTop: '40px', width: '100%' }}>
                        <Form
                            form={form}
                            name="contactUs"
                            style={{ width: '100%' }}
                            initialValues={{ initialValues }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item<ContactUsType>
                                name="yourName"
                                rules={[{ required: true, message: 'Please, fill this field!' }]}
                            >
                                <Input placeholder='Your Name' size={'large'}/>
                            </Form.Item>
                            <Form.Item<ContactUsType>
                                name="email"
                                rules={[{ required: true, message: 'Please, fill this field!' },
                                  {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                  }]}
                            >
                                <Input placeholder='Email address' size={'large'}/>
                            </Form.Item>
                          <Form.Item<ContactUsType>
                            name="message"
                            rules={[{ required: true, message: 'Please, fill this field!' }]}
                        >
                            <TextArea rows={6} placeholder='Your Message' size={'large'}/>
                        </Form.Item>
                            <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                                <FlexStyled>
                                    <Button type={'submit'} variant="contained" color="success">Send</Button>
                                </FlexStyled>
                            </Form.Item>
                            {
                                renderMessageErrorRegistration
                            }
                        </Form>
                    </Flex>
                        </Spin>
                    </FlexMainStyled>
                </ColStyled>
                <ColStyled xs={24} sm={24} md={12} lg={12} xl={12}>
                    <ContactUsLocation />
                </ColStyled>
            </RowStyled>
            </Context.Provider>
        </DivMinWidth>
    </div>
  );
};
