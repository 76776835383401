export const colors = {
  colorTextDark: 'rgb(42, 53, 71)', // from Logo
  colorGreen: '#44A92E', // from Logo
  colorTextLight: 'rgb(112 121 134)',
  colorGreen2: '#387C35', // например, текст на кнопках
  colorLGreen: '#EEF1EF',
  colorFill: '#000000',
  colorReproduction: '#f50',
  colorOriginal: '#722ED1',
  colorState: '#2db7f5',
  colorGrey: '#F5F5F5',
};

export const fonts = {
  font3: 'Plus Jakarta Sans, sans-serif',
  font2: 'Poppins, sans-serif',
};
