import { configureStore } from '@reduxjs/toolkit';
import spinnerReducer from './slices/spinnerSlice';
import oldMapReducer from './slices/oldMapSlice';

export const store = configureStore({
  reducer: {
    spinner: spinnerReducer,
    oldMap: oldMapReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
