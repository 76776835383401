import React from 'react';
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { useSelector } from 'react-redux';
import { ButtonToStore } from './button/ButtonToStore';
import { RootState } from '../store';

const useMenuItems = () => {
  const counterMainImgClick = useSelector<RootState, number>((state) => state.oldMap.counterMainImgClick);
  const navigate = useNavigate();

  return [
    {
      key: '1',
      label: <a
                href="#home"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/');
                  scroller.scrollTo('home', {
                    smooth: true,
                    offset: -70,
                    duration: 500,
                  });
                }}
            >
                Home
            </a>,
    },
    {
      key: '2',
      label: <a
                href="#about"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/');
                  scroller.scrollTo('about', {
                    smooth: true,
                    offset: -70,
                    duration: 500,
                  });
                }}
            >
                About us
            </a>,
    },
    {
      key: '3',
      label: <a
                href="#location"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/');
                  scroller.scrollTo('location', {
                    smooth: true,
                    offset: -70,
                    duration: 500,
                  });
                }}
            >
                Locations
            </a>,
    },
    {
      key: '4',
      label: <a
                href="#contact"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/');
                  scroller.scrollTo('contact', {
                    smooth: true,
                    offset: -70,
                    duration: 500,
                  });
                }}
            >
                Contact us
            </a>,
    },
    counterMainImgClick >= 10 ? {
      key: '5',
      label: <div className={'custom_item_menu'}><ButtonToStore buttonText={'Online store'} /></div>,
      className: 'custom-menu-item-last',
    } : null,
  ];
};

export default useMenuItems;
