import React from 'react';
import { Flex } from 'antd';
import { useSelector } from 'react-redux';
import { OldMapItemType } from '../../../common/types';
import { ShopItemCheckout } from './ShopItemCheckout';
import { RootState } from '../store';

export const CheckoutCart: React.FC = () => {
  const oldMapCart = useSelector<RootState, OldMapItemType[] | null>((state) => state.oldMap.oldMapCart);
  const totalPrice = useSelector<RootState, number>((state) => state.oldMap.orderDetail.totalPrice);
  const maxShipCost = useSelector<RootState, number>((state) => state.oldMap.orderDetail.maxShipCost);
  const finalPrice = useSelector<RootState, number>((state) => state.oldMap.orderDetail.finalPrice);

  return (
        <div style={{ padding: 40 }}>
            <h2 style={{ marginBottom: 20 }}>Your order</h2>
            {oldMapCart && oldMapCart.map((item, index) => (
                <div key={index}>
                    <ShopItemCheckout item={item}/>
                </div>
            ))}
            <Flex vertical gap={10}>
                <Flex align={'center'} justify={'space-between'}>
                    <h4>Subtotal · {oldMapCart?.length} items </h4>
                    <h4>${totalPrice}</h4>
                </Flex>
                <Flex align={'center'} justify={'space-between'}>
                    <h4>Shipping</h4>
                    <h4>${maxShipCost}</h4>
                </Flex>
            </Flex>
            <Flex align={'center'} justify={'space-between'} style={{ marginTop: 20 }}>
                <h2>Total</h2>
                <Flex align={'baseline'} gap={5}>
                    <span style={{ fontSize: '11px' }}>USD</span>
                    <h2>${finalPrice} </h2>
                </Flex>
            </Flex>
        </div>
  );
};
