import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import { Footer, Header } from 'antd/es/layout/layout';
import styled from 'styled-components';
import { Content } from 'antd/lib/layout/layout';
import { HeaderMain } from '../components/layout/HeaderMain';
import { FooterMain } from '../components/layout/FooterMain';
import { Breadcrumbs } from '../components/Breadcrumbs';

interface IMainTemplatePage {
  children: ReactNode | string
}

export const HeaderStyled = styled(Header)`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: sticky;
  z-index: 100;
  top: 0px;
  left: auto;
  right: 0px;
  box-shadow: none;
  background: rgb(255, 255, 255);
  backdrop-filter: blur(4px);
  padding: 0;
`;

export const MainTemplatePage: React.FC<IMainTemplatePage> = (props) => (
    <>
        <Layout style={{
          display: 'flex',
          minHeight: '100vh',
          margin: '0 auto',
        }}>
            <HeaderStyled>
                <HeaderMain/>
            </HeaderStyled>
            <Content style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                <div className="site-layout-content" style={{
                  display: 'flex', flex: '1', overflowY: 'auto', flexDirection: 'column',
                }}>
                    <Breadcrumbs />
                    {props.children}
                </div>
            </Content>
            <Footer>
                <FooterMain/>
            </Footer>
        </Layout>
    </>
);
