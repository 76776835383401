import * as React from 'react';
import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import {
  Button, Divider, Flex, Form, Input, notification, Spin,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';
import { NotificationFilled } from '@ant-design/icons';
import { Mail } from '@mui/icons-material';
import axios from 'axios';
import { useAlertMessage } from '../hook/useAlertMessage';
import { OldMapItemType, RequestSizeType } from '../../../../common/types';
import { FlexStyled } from '../../pages/storePage/StoreItemPage';
import { paths } from '../../consts';

const DivFormStyled = styled.div`

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Context = React.createContext({ name: 'Default' });

interface IRequestSizeForm {
  refButton: React.MutableRefObject<any>,
  handleForm: Dispatch<SetStateAction<any>>,
  item: OldMapItemType
}

export const RequestSizeForm: React.FC<IRequestSizeForm> = ({ refButton, handleForm, item }) => {
  const [spinModalRegistration, setSpinModalRegistration] = useState(false);
  const [msgRegistration, setMsgRegistration] = useState('');
  const [api] = notification.useNotification();
  const [form] = Form.useForm();

  const openNotification = ((text: string) => {
    api.info({
      message: 'Request process',
      description: <Context.Consumer>{() => `${text}!`}</Context.Consumer>,
      icon: <NotificationFilled style={{ color: '#108ee9' }}/>,
    });
  });

  const initialValues: RequestSizeType = {
    yourName: '', message: '', email: '',
  };

  const onFinish = useCallback(async (values: any) => {
    console.log(values);
    setSpinModalRegistration(true);
    setMsgRegistration('');
    try {
      const result = (await axios.post(`${process.env.REACT_APP_REST_API}${paths.USER_REQUEST}`, [values, item])).data;
      form.resetFields();
      openNotification(result.message);

      if (handleForm) {
        handleForm(true);
      }
    } catch (e: any) {
      setMsgRegistration(e.response.data.message);
    }
    setSpinModalRegistration(false);
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const renderMessageErrorRegistration = useAlertMessage('error', msgRegistration);

  return (
        <DivFormStyled>
            <Spin spinning={spinModalRegistration} tip='Please wait' style={{ width: '100%' }}>
                <Form
                    form={form}
                    name="ContactUs"
                    style={{ width: '100%' }}
                    initialValues={{ initialValues }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size={'large'}
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 17 }}
                    labelAlign={'left'}
                >
                    <h3 style={{ margin: 0, fontWeight: 600 }}>Request a Custom Size</h3>
                    <Divider/>
                    <div>
                        <p>Your perfect size is just one form away. Let us know your preferred measurements, and we’ll handle the rest.</p>
                    </div>
                    <FlexStyled vertical style={{ margin: '20px 0' }}>
                        <h3 style={{ fontWeight: 600 }}>Custom Size for:</h3>
                        <Flex justify={'space-between'}>
                            <p><span style={{ fontWeight: 'bolder' }}>Name:</span> {item.name}</p>
                            <p><span style={{ fontWeight: 'bolder' }}>ItemID: </span>{item.id}</p>
                        </Flex>
                    </FlexStyled>
                    <Form.Item<RequestSizeType>
                        name="yourName"
                        label="Your Name"
                        rules={[{ required: true, message: 'Please, fill this field!' }]}
                    >
                        <Input placeholder='Your Name'/>
                    </Form.Item>
                    <Form.Item<RequestSizeType>
                        name="email"
                        label="E-mail"
                        rules={[{ required: true, message: 'Please, fill this field!' },
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          }]}
                    >
                        <Input type={'email'} addonBefore={<Mail color={'success'}/>} maxLength={20}/>
                    </Form.Item>
                    <Form.Item<RequestSizeType>
                        name="message"
                        label="Your Message"
                        rules={[{ required: true, message: 'Please, fill this field!' }]}
                        style={{ marginBottom: 0 }}
                    >
                        <TextArea rows={6} placeholder='Text us about your desired size'/>
                    </Form.Item>
                    <Flex>
                        <Button type="primary" htmlType="submit" ref={refButton} aria-label={'Send'}
                                style={{ marginBottom: 0, opacity: refButton ? 0 : 1 }}>
                            Send
                        </Button>
                    </Flex>
                    {
                        renderMessageErrorRegistration
                    }
                </Form>
            </Spin>
        </DivFormStyled>
  );
};
