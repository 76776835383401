import { Button } from '@mui/material';
import React from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { colors } from '../../assets/constStyles';

const ButtonStyled = styled(Button)`
  width: 100%;
  border-color: ${colors.colorGreen}!important;
  color: ${colors.colorGreen2}!important;
  letter-spacing: 1.17px!important;

  &:hover{
    transition: all 0.1s ease-in 0s;
    background-color: ${colors.colorGreen2}!important;
    color: white!important;
  }
`;

interface IButtonToCart {
  text?: string;
}

export const ButtonInfo: React.FC<IButtonToCart> = ({ text }) => (
        <Flex justify={'start'} style={{ width: '100%' }}>
            <ButtonStyled
                type={'button'}
                variant="outlined"
                size={'large'}>
              {text}
            </ButtonStyled>
        </Flex>
);
