import React from 'react';
import styled from 'styled-components';
import { Divider, Flex } from 'antd';
import { DeliveryInfo } from './DeliveryInfo';
import { PaymentInfo } from './PaymentInfo';

const FlexStyled = styled(Flex)`
  padding: 40px 20px;

  @media (max-width: 768px) {
    padding: 20px 0px;
  }
`;

export const CheckoutUserData: React.FC = () => (
    <FlexStyled vertical>
        {
            <DeliveryInfo />
        }
        {<Divider />}
        {
            <PaymentInfo />
        }
    </FlexStyled>
);
