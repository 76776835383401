import React from 'react';
import { Flex } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { paths } from '../consts';

const LogoStyle = styled.div`
  display: block;
  background: transparent url('../img/logo.png') no-repeat center center;
  text-indent: -9999px;
  width: 70px;
  height: 70px;
  background-size: contain;
`;

const SpanStyled = styled.span`
  font-size: 20px;
  font-weight: 800;
  font-family: 'Ubuntu', sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const Logo: React.FC = () => (
    <Link to={paths.MAIN} style={{ display: 'block', flex: '0 1 auto' }}>

        <Flex wrap={'nowrap'} gap={10} align={'center'}>
            <LogoStyle>OLD CHARTS</LogoStyle>
            <Flex vertical>
                <SpanStyled>OLD CHARTS </SpanStyled>
                <SpanStyled>OF NEW ENGLAND</SpanStyled>
            </Flex>
        </Flex>
    </Link>
);
