import React from 'react';
import styled from 'styled-components';
import { colors } from '../../assets/constStyles';
import { DivMinWidth } from '../../App';

const DivStyled = styled.div`
  padding: 20px 0;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const FooterMain: React.FC = () => (
    <div style={{ backgroundColor: colors.colorFill, color: 'white' }}>
        <DivMinWidth>
            <DivStyled>
                <p style={{ fontSize: '0.8em' }}>Ditime ©Copyright 2025</p>
            </DivStyled>
        </DivMinWidth>
    </div>
);
