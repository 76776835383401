import React from 'react';
import { Col, Flex, Row } from 'antd';
import styled from 'styled-components';
import { colors } from '../../assets/constStyles';
import { H1Styled, H4Styled } from '../ContentHeader';
import { DivMinWidth } from '../../App';

const RowStyled = styled(Row)`
  margin: 0 auto;
  display: flex;
`;

const ColStyled = styled(Col)`
  display: flex;
`;

const FlexStyled = styled(Flex)`
  width: 100%;
  padding: 100px 40px 40px 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const AboutUs: React.FC = () => (
    <div style={{ backgroundColor: colors.colorLGreen }} id="about">
        <DivMinWidth>
            <RowStyled >
                <ColStyled xs={24} sm={24} md={12} lg={12} xl={12}>
                    <FlexStyled vertical>
                        <H4Styled>Who we are</H4Styled>
                        <H1Styled>About Us</H1Styled>
                    </FlexStyled>
                </ColStyled>
                <ColStyled xs={24} sm={24} md={12} lg={12} xl={12}>
                    <FlexStyled vertical style={{ fontSize: '18px', textAlign: 'justify' }}>
                        <h3 style={{ paddingBottom: '30px' }}>We specialize in Original 19th Century Antique Nautical Charts and Historical Maps.</h3>
                        <h4 style={{ fontWeight: 500 }}>Old Charts of New England also offers fine quality reproductions of all our original antique nautical charts and historical maps.  Our primary focus on this website are the Coast Survey Charts, charts by Blunt, Des Barres, Fielding to name a few.  We also offer fine reproductions that are scanned to very high resolution and printed on Swiss made paper with 200 year archival ink, everything is done in house.</h4>
                    </FlexStyled>
                </ColStyled>
            </RowStyled>
        </DivMinWidth>
    </div>
);
