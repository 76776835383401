import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { paths } from '../../consts';
import { ContactUserType, OldMapItemType, StoreOldMapIteType } from '../../../../common/types';

export interface IMapState {
  oldMapItem: OldMapItemType | null,
  oldMapList: StoreOldMapIteType,
  oldMapCart: OldMapItemType[],
  oldMapLoader: boolean,
  oldMapNotFound: boolean,
  selectedSize: string,
  cartCount: number,
  totalPrice: number,
  orderDetail: {
    id: string,
    items: StoreOldMapIteType,
    userData: ContactUserType,
    totalPrice: number,
    finalPrice: number,
    maxShipCost: number
  }
  counterMainImgClick: number,
  deliveryInfoFilled: boolean
}

const initialState: IMapState = {
  oldMapItem: null,
  oldMapList: {},
  oldMapCart: [],
  oldMapLoader: false,
  oldMapNotFound: false,
  selectedSize: '',
  cartCount: 0,
  totalPrice: 0,
  orderDetail: {
    id: '',
    items: {},
    userData: {
      id: '',
      address: '',
      city: '',
      cityState: '',
      apartment: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      street: '',
      postalCode: '',
      state: '',
    },
    totalPrice: 0,
    finalPrice: 0,
    maxShipCost: 0,
  },
  counterMainImgClick: 0,
  deliveryInfoFilled: false,
};

export const fetchListOldMaps = createAsyncThunk('listOldMaps/fetch', async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_REST_API}${paths.LIST_SERVICES}`);
  return data as any;
});

export const oldMapSlice = createSlice({
  name: 'oldMap',
  initialState,
  reducers: {
    setterApp: (state, action: PayloadAction<Partial<IMapState>>) => ({ ...state, ...action.payload }),

    setCurrentOldMap: (state, action: PayloadAction<OldMapItemType>) => {
      state.oldMapItem = action.payload;
    },
    setOldMapCart: (state, action: PayloadAction<OldMapItemType>) => {
      state.oldMapCart = [...state.oldMapCart, action.payload];
      state.cartCount = state.oldMapCart.length;
      state.totalPrice += action.payload.price;
    },
    setSelectedSize: (state, action: PayloadAction<string>) => {
      state.selectedSize = action.payload;
    },
    delMapFromList: (state, action: PayloadAction<OldMapItemType>) => {
      const mapToDelete = action.payload;
      const index = state.oldMapCart.findIndex((map) => map.id === mapToDelete.id && map.price === mapToDelete.price);

      if (index !== -1) {
        state.oldMapCart.splice(index, 1);
      }

      state.totalPrice -= action.payload.price;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListOldMaps.pending, (state) => {
      state.oldMapLoader = true;
    });

    builder.addCase(fetchListOldMaps.fulfilled, (state, action: PayloadAction<StoreOldMapIteType>) => {
      if (!action.payload) {
        state.oldMapNotFound = true;
      } else {
        state.oldMapNotFound = false;
      }
      state.oldMapList = action.payload;
      // console.log(action.payload);
      state.oldMapLoader = false;
    });
  },
});

export const {
  setCurrentOldMap,
  setOldMapCart,
  setSelectedSize,
  delMapFromList,
  setterApp,
} = oldMapSlice.actions;

export default oldMapSlice.reducer;
