import React from 'react';
import {
  Col, Flex, Image, InputNumber, InputNumberProps, Row, Tag,
} from 'antd';
import { colors } from '../assets/constStyles';
import { OldMapItemType } from '../../../common/types';
import { ButtonRemove } from './button/ButtonRemove';

interface IShopItem {
  item: OldMapItemType
}

export const ShoppingBagItem: React.FC<IShopItem> = ({ item }) => {
  const colorTag = item && item.type === 'Original' ? colors.colorOriginal : colors.colorReproduction;

  const onChange: InputNumberProps['onChange'] = (value) => {
    console.log('changed', value);
  };

  return (
        <Row gutter={20}>
            <Col className="gutter-row" xs={8} sm={8} md={8} lg={8} xl={8}>
                <Image
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    src={`${process.env.REACT_APP_S3_STORAGE_URL_COMMON}${process.env.REACT_APP_BUCKET_COMMON_IMAGE}${item.link}`}
                    loading="lazy"
                    preview={false}
                    wrapperStyle={{ height: '100%', paddingBottom: '20px' }}
                />
            </Col>
            <Col className="gutter-row" xs={16} sm={16} md={16} lg={16} xl={16}>
                <Row style={{ paddingBottom: '10px' }}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Flex justify={'flex-end'}><span>ItemID: {item.id}</span> </Flex>
                        <h3>{item.name}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                        {item.type && <Tag color={colorTag}>{item.type}</Tag>}
                        {item.state && <Tag color={colors.colorState}>{item.state}</Tag>}
                        <Tag color={colors.colorGreen}>{`${item.size.width} x ${item.size.height}`}</Tag>
                    </Col>
                </Row>
                <Row style={{ padding: '10px 0' }}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Flex gap={10} align={'center'}>
                            {/* <Tag color={'green'} style={{padding: '10px'}}>{`${item.size.width} x ${item.size.height}`}</Tag> */}
                            <InputNumber min={1} max={1} defaultValue={1} onChange={onChange} disabled={true} style={{ width: 40 }}/>
                            <ButtonRemove item={item}/>
                        </Flex>
                    </Col>
                </Row>
                <Row style={{ textAlign: 'right' }}>
                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h3 style={{ fontWeight: 'bold' }}>${item.price}</h3>
                    </Col>
                </Row>
            </Col>
        </Row>
  );
};
