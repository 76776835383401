import React from 'react';
import './assets/App.css';
import './assets/AntRe.css';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';
import { useJsApiLoader } from '@react-google-maps/api';
import { paths } from './consts';
import { MainTemplatePage } from './pages/MainTemplatePage';
import { MainPage } from './pages/MainPage';
import { StorePage } from './pages/storePage/StorePage';
import { StoreItemPage } from './pages/storePage/StoreItemPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { LoginPage } from './pages/LoginPage';
import { API } from './common/constsAPI';
import { LIBRARIES } from './common/consts';

export const DivMinWidth = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

function App() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API.GOOGLE_MAPS as string,
    libraries: LIBRARIES as any[],
  });

  console.log(isLoaded);

  axios.interceptors.request.use(async (config) => {
    const headers = config.headers as any;

    if (config.url && config.url.includes(process.env.REACT_APP_REST_API!)) {
      headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    }

    return config;
  }, () => {
  });

  return (
        <>
            <HelmetProvider>
                <title>Old Charts | Buy Antique Nautical Charts & Historical Maps</title>
                <meta
                    name="description"
                    content="Buy Antique Nautical Charts & Historical Maps"
                />
                <link rel="canonical" href='https://oldcharts.com'/>
            </HelmetProvider>
            <Router>
                <Routes>
                    <Route path={paths.STORE }
                           element={
                                <MainTemplatePage >
                                    <StorePage/>
                                </MainTemplatePage>}/>
                    <Route path={`${paths.STORE_ITEM}/:id`}
                           element={
                                <MainTemplatePage >
                                    <StoreItemPage />
                                </MainTemplatePage>}/>
                    <Route path={paths.CHECKOUT}
                           element={
                               <MainTemplatePage >
                                   <CheckoutPage />
                               </MainTemplatePage>
                            }
                    />
                    <Route path={paths.LOGIN}
                           element={
                               <MainTemplatePage >
                                   <LoginPage />
                               </MainTemplatePage>
                           }
                    />
                    <Route path={paths.MAIN}
                           element={
                               <MainTemplatePage >
                                   <MainPage/>
                               </MainTemplatePage>
                           }
                    />
                </Routes>
            </Router>
        </>
  );
}

export default App;
