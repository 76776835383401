import { Button } from '@mui/material';
import React from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { colors } from '../../assets/constStyles';
import { AppDispatch } from '../../store';
import { setOldMapCart } from '../../store/slices/oldMapSlice';
import { MapTypeType, OldMapItemType } from '../../../../common/types';

const ButtonStyled = styled(Button)`
  width: 100%;
  letter-spacing: 1.17px!important;
  background-color: ${colors.colorGreen2}!important;
  color: white!important;

  &:hover{
    transition: all 0.1s ease-in 0s;
    border-color: #295d27 !important;
  }
`;

interface IButtonToCart {
  text?: string;
  item: OldMapItemType
}

export const ButtonToCart: React.FC<IButtonToCart> = ({ text, item }) => {
  const dispatch = useDispatch<AppDispatch>();

  const addItem = () => {
    dispatch(setOldMapCart({
      id: item.id,
      link: item.link,
      type: item.type as MapTypeType,
      state: item.state,
      name: item.name,
      description: item.description,
      size: {
        width: item.size.width,
        height: item.size.height,
      },
      price: item.price,
      oriental: item.oriental,
      priceOriginal: item.priceOriginal,
      sizePx: {
        width: item.sizePx.width,
        height: item.sizePx.height,
      },
      dateByCreate: 1,
      dateByUpdate: 1,
      isActive: 'No',
      shipCost: item.shipCost,
    }));
  };

  return (
        <Flex justify={'start'} style={{ width: '100%' }}>
            <ButtonStyled
                variant='outlined'
                type={'button'}
                size={'large'}
                onClick={addItem}>
              {text || 'Add to bag'}
            </ButtonStyled>
        </Flex>
  );
};
