import React from 'react';
import { ContentHeader } from '../ContentHeader';
import { ContentBody } from '../ContentBody';

export const ContentMain: React.FC = () => (
    <>
        <ContentHeader />
        <ContentBody />
    </>
);
