import React from 'react';
import { Flex, Menu } from 'antd';
import styled from 'styled-components';
import { MenuOutlined } from '@mui/icons-material';
import useMenuItems from './menuItems';
import { colors } from '../assets/constStyles';

const MenuStyled = styled(Menu)`
  width: 100%;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0px;
    flex-direction: column;
    width: auto;
    margin-bottom: -4px!important;


    && .ant-menu-submenu-selected::after{
      border-bottom-color: white;
    }
  }
`;

export const MainMenu: React.FC = () => {
  const menuItems = useMenuItems();

  return (
          <Flex style={{ alignItems: 'center', flex: 1 }}>
              <MenuStyled
                  defaultSelectedKeys={['1']}
                  mode="horizontal"
                  items={menuItems}
                  overflowedIndicator={<MenuOutlined style={{
                    paddingLeft: '10px', paddingRight: '10px', fontSize: 50, color: colors.colorTextDark,
                  }} />}
              />
          </Flex>
  );
};
