import React from 'react';
import { Flex } from 'antd';
import { OldMapItemType } from '../../../common/types';
import { StoreCardItemMini } from '../pages/storePage/StoreCardItemMini';
import { colors } from '../assets/constStyles';

interface IRecommendedBlock{
  items: { [key: string]: OldMapItemType };
}

export const RecommendedBlock: React.FC<IRecommendedBlock> = ({ items }) => (
    <div style={{ backgroundColor: colors.colorGrey }}>
        <div style={{ padding: '40px 24px' }}>
            <Flex justify={'center'} align={'center'} style={{ paddingBottom: '24px' }}>
                <h2 style={{
                  lineHeight: '22px', fontWeight: 500, textTransform: 'uppercase', letterSpacing: '1.17px',
                }}>Recommended for you</h2>
            </Flex>
            <Flex gap={10}>
                {Object.values(items).map((item) => <StoreCardItemMini key={item.id} dataMap={item}/>)}
            </Flex>
        </div>
    </div>
);
