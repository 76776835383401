import * as React from 'react';
import { Flex } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';
import { H1Styled, H4Styled } from '../../components/ContentHeader';
import { DivMinWidth } from '../../App';
import { StoreItems } from './StoreItems';

const FlexHeaderStyled = styled(Flex)`
  width: 100%;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const StorePage: React.FC = () => (
    <>
        <HelmetProvider>
            <title>Old Charts | Store</title>
            <meta
                name="description"
                content="Buy Antique Nautical Charts & Historical Maps"
            />
            <link rel="canonical" href='https://oldcharts.com/store'/>
        </HelmetProvider>
        <div style={{ position: 'relative' }}>
            <DivMinWidth>
                <FlexHeaderStyled vertical>
                    <H4Styled>Our store</H4Styled>
                    <H1Styled>Explore our offerings</H1Styled>
                </FlexHeaderStyled>
                <StoreItems />
            </DivMinWidth>
        </div>
    </>

);
