import React, { useCallback } from 'react';
import { Alert, Row } from 'antd';
import styled from 'styled-components';

type MsgType = 'error' | 'success';

const AlertStyle = styled(Alert)`
  width: 100%;
  margin-bottom: 20px; 
`;

export const useAlertMessage = (type: MsgType, msg: string) => useCallback(() => {
  if (msg) {
    return (<Row key={`key_${Math.random()}`}>
            <AlertStyle message={<div dangerouslySetInnerHTML={{ __html: `${msg}` }} />} type={type} showIcon />
        </Row>);
  }
  return null;
}, [msg])();
