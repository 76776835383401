import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/index.css';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
    <Provider store={store}>
        <Suspense fallback={<div>Loading ...</div>}>
            <App/>
        </Suspense>
    </Provider>,
);
