import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Card, CardContent, CardMedia } from '@mui/material';
import { Flex, Tag } from 'antd';
import { OldMapItemType } from '../../../../common/types';
import { colors } from '../../assets/constStyles';
import { paths } from '../../consts';
import { ButtonInfo } from '../../components/button/ButtonInfo';

const DivStyled = styled.div`
  min-width: 350px;
  width: 30%;
  display: flex;
  flex-direction: column;
`;

const CardStyled = styled(Card)`
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(145, 158, 171, 0.3) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;

  &:hover {
    transform: scale(1.01);
    transition: all 0.1s ease-in 0s;
  }
`;

const SpanStyled = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  font-weight: 400;
  min-height: 40px
`;

const TextStyled = styled.p`
  line-height: 1.5em;
  font-size: 1.5em;
  min-height: calc(2 * 1.5em);
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;

  @media (max-width: 768px) {
    line-height: 1.1em;
    font-size: 1.1em;
    min-height: calc(2 * 1.1em);
  }
  
`;

const TagStateStyled = styled(Tag)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const FlexDescriptionStyled = styled(Flex)`
  text-align: justify;
  padding-top: 20px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  

  @media (max-width: 768px) {
    padding-top: 10px;
    
  }
`;

export const FlexTagStyled = styled(Flex)`
  padding: 20px 0px;

  @media (max-width: 768px) {
    padding: 10px 0px;
  }
`;

export const TagSizeStyled = styled(Tag)`
  font-size: 1.2em;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

interface IStoreOneItem {
  dataMap: OldMapItemType
}

export const StoreCardItem: React.FC<IStoreOneItem> = ({ dataMap }) => {
  const colorTag = dataMap && dataMap.type === 'Original' ? colors.colorOriginal : colors.colorReproduction;

  return (
        <DivStyled>
            <CardStyled variant="outlined" sx={{
              border: 'none', display: 'flex', flexDirection: 'column', flexGrow: 1,
            }}>
                <Link to={`${paths.STORE_ITEM}/${dataMap.id}`}>
                    <CardMedia
                        component="img"
                        height="300"
                        image={`${process.env.REACT_APP_S3_STORAGE_URL_COMMON}${process.env.REACT_APP_BUCKET_COMMON_IMAGE}${dataMap.link}`}
                        alt="chart"
                    />
                    <CardContent style={{ flexGrow: 1 }}>
                        <TextStyled>
                            {dataMap.name}
                        </TextStyled>
                        <Flex>
                            <Tag color={colorTag}>{dataMap.type}</Tag>
                            {
                                dataMap.state && <TagStateStyled color={colors.colorState}>{dataMap.state}</TagStateStyled>
                            }
                        </Flex>
                        <FlexDescriptionStyled>
                            {
                                dataMap.description
                                  ? <SpanStyled style={{ color: colors.colorTextDark }}>
                                        {dataMap.description}
                                    </SpanStyled>
                                  : <SpanStyled>no description</SpanStyled>
                            }
                        </FlexDescriptionStyled>
                        <FlexTagStyled>
                            <TagSizeStyled
                                color={colors.colorGreen}
                            >
                                {`${dataMap.size.width} x ${dataMap.size.height}`}
                            </TagSizeStyled>
                        </FlexTagStyled>
                        <Flex style={{ paddingBottom: '20px' }}>
                            <h2 style={{ color: colors.colorTextDark }}>
                                ${dataMap.price}
                            </h2>
                        </Flex>
                        <ButtonInfo text={'More info'}/>
                    </CardContent>
                </Link>
            </CardStyled>
        </DivStyled>
  );
};
