import { Breadcrumb } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { paths } from '../consts';
import { DivMinWidth } from '../App';

export const Breadcrumbs = () => {
  const location = useLocation();
  const { id } = useParams();

  if (location.pathname === paths.MAIN) {
    return null;
  }

  const items = [
    {
      title: <><HomeOutlined style={{ marginRight: 5 }}/>Home</>,
      href: paths.MAIN,
    },
    {
      title: <p>Store</p>,
      href: paths.STORE,
    },
  ];

  if (location.pathname === paths.CHECKOUT) {
    items.push({
      title: <p>Checkout</p>,
      href: paths.CHECKOUT,
    });
  }

  if (id) {
    items.push({
      title: <p>Item {id}</p>,
      href: `${paths.STORE_ITEM} / ${id}`,
    });
  }

  return (
      <div style={{ position: 'relative' }}>
        <DivMinWidth>
            <div style={{ padding: '10px 20px' }}>
                <Breadcrumb items={items}/>
            </div>
        </DivMinWidth>
      </div>
  );
};
