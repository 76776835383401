import React from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Logo } from '../Logo';
import { ShoppingBag } from '../ShoppingBag';
import { MainMenu } from '../MainMenu';
import { DivMinWidth } from '../../App';
import { RootState } from '../../store';

const FlexStyled = styled(Flex)`
  padding: 20px 24px;
  position: relative;
  width: 100%;
  max-width: 100%;
  box-shadow: rgba(14, 32, 45, 0.08) 0px 24px 56px 0px;
  border-radius: 16px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const HeaderMain: React.FC = () => {
  const counterMainImgClick = useSelector<RootState, number>((state) => state.oldMap.counterMainImgClick);

  return (
        <DivMinWidth>
            <FlexStyled align={'center'}>
                <Logo />
                <MainMenu />
                {
                    counterMainImgClick >= 10 && <ShoppingBag />
                }
            </FlexStyled>
        </DivMinWidth>
  );
};
