import React from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { colors } from '../assets/constStyles';
import { DivMinWidth } from '../App';
import { ButtonToStore } from './button/ButtonToStore';
import { RootState } from '../store';

export const H4Styled = styled.h4`
  color: ${colors.colorGreen};
  text-transform: uppercase;

  @media (max-width: 768px) {
    
  }
`;

export const H1Styled = styled.h1`
  color: ${colors.colorTextDark};
  font-size: 2em;

  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const FlexMainStyled = styled(Flex)`
  margin-top: 60px;
  padding: 0 20px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const FlexStyled = styled(Flex)`
  margin: 30px 0;
`;

export const ContentHeader: React.FC = () => {
  const counterMainImgClick = useSelector<RootState, number>((state) => state.oldMap.counterMainImgClick);

  return (
        <DivMinWidth id="home">
            <FlexMainStyled align={'center'} vertical>
                <H4Styled style={{ paddingBottom: '20px' }}>OLD CHARTS OF NEW ENGLAND </H4Styled>
                <H1Styled>Explore Original 19th Century</H1Styled>
                <H1Styled style={{ textAlign: 'center' }}>Antique Nautical Charts and Historical Maps</H1Styled>
                {
                    counterMainImgClick >= 10 && <FlexStyled>
                    <ButtonToStore buttonText={'Buy now'} />
                  </FlexStyled>
                }
            </FlexMainStyled>
        </DivMinWidth>
  );
};
