import React, { useEffect, useState } from 'react';
import {
  Badge, Col, Drawer, Empty, Flex, Row,
} from 'antd';
import { ShoppingBagOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider } from '@mui/material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { colors } from '../assets/constStyles';
import { AppDispatch, RootState } from '../store';
import { OldMapItemType, StoreOldMapIteType } from '../../../common/types';
import { ShoppingBagItem } from './ShoppingBagItem';
import { paths } from '../consts';
import { RecommendedBlock } from './RecommendedBlock';
import { setterApp } from '../store/slices/oldMapSlice';

const ButtonCheckoutStyled = styled(Button)`
  width: 100%;
  border-color: ${colors.colorGreen} !important;
  color: white !important;
  letter-spacing: 1.7px !important;
  background-color: ${colors.colorGreen2} !important;

  &:hover {
    transition: all 0.1s ease-in 0s;
    background-color: #295d27 !important;
  }

  @media (max-width: 768px) {

  }
`;

const FlexDrawerHeaderStyled = styled(Flex)`
  font-size: 1.3em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2em;
`;

const DivCheckoutStyled = styled.div`
  padding: 24px;
  flex: 0 0 auto;
`;

const DivBodyCartItemsStyled = styled.div`
  flex: 1 1 auto;
  overflow-y: auto
`;

const FlexContainerStyled = styled(Flex)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

export const ShoppingBag: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [maxShipCost, setMaxShipCost] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const oldMapCart = useSelector<RootState, OldMapItemType[] | null>((state) => state.oldMap.oldMapCart);
  const cartCount = oldMapCart?.length;
  const listOldMap = useSelector<RootState, StoreOldMapIteType>((state) => state.oldMap.oldMapList);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (oldMapCart) {
      const newTotalPrice = oldMapCart.reduce((acc, item) => acc + item.price, 0);
      setTotalPrice(newTotalPrice);
      const newMaxShipCost = oldMapCart.reduce((max, item) => (item.shipCost > max ? item.shipCost : max), 0) || 10;
      setMaxShipCost(newMaxShipCost);
      setFinalPrice(newTotalPrice + newMaxShipCost);
    }
  }, [oldMapCart]);

  const getItemsCartForSetter = (items: OldMapItemType[]) => {
    const newObj = {} as StoreOldMapIteType;
    items.forEach((item) => {
      newObj[item.id] = {
        ...item,
      };
    });

    return newObj;
  };

  const checkoutHandle = () => {
    setOpen(false);
    if (oldMapCart) {
      dispatch(setterApp({
        orderDetail: {
          id: uuidv4(),
          userData: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address: '',
            street: '',
            apartment: '',
            city: '',
            postalCode: '',
            cityState: '',
            state: '',
          },
          items: getItemsCartForSetter(oldMapCart),
          totalPrice,
          maxShipCost,
          finalPrice,
        },
      }));
    }
  };

  // const maps = Object.fromEntries(Object.entries(listOldMap).slice(0, 3));
  const maps = Object.fromEntries(Object.entries(listOldMap).sort(() => Math.random() - 0.5).slice(0, 3));

  return (
        <Flex style={{ padding: '0 20px' }}>
            <Badge count={cartCount} showZero>
                <ShoppingBagOutlined style={{ fontSize: '30px', color: colors.colorGreen2, cursor: 'pointer' }} onClick={showDrawer}/>
            </Badge>
            <Drawer
                title={
                    <FlexDrawerHeaderStyled justify={'center'}>Your Bag</FlexDrawerHeaderStyled>
                }
                onClose={onClose}
                open={open}
                size={'large'}
                styles={{
                  body: {
                    padding: 0, display: 'flex', flexDirection: 'column', height: '100%',
                  },
                }}
            >
                {
                    !oldMapCart || !oldMapCart.length
                      ? <Empty description={'No item'}/>
                      : <FlexContainerStyled>
                            <DivBodyCartItemsStyled>
                                <Flex vertical gap={20} style={{ padding: '10px 24px' }}>
                                    {oldMapCart.map((item, index) => (
                                        <div key={index}>
                                            <ShoppingBagItem item={item}/>
                                            <Flex justify={'center'}>
                                                {index < oldMapCart.length - 1 && <Divider style={{ width: '50%' }} />}
                                            </Flex>
                                        </div>
                                    ))}
                                </Flex>
                                <Flex justify={'flex-end'} style={{ padding: '0 24px' }} >
                                    <h2>${totalPrice}</h2>
                                </Flex>
                                <RecommendedBlock items={maps} />
                            </DivBodyCartItemsStyled>
                            <DivCheckoutStyled >
                                <Flex justify={'space-between'} style={{ padding: '10px 24px' }} align={'center'}>
                                  <h3 style={{ fontWeight: 'bolder' }}>TOTAL</h3>
                                  <h2><span style={{ fontSize: '11px', fontWeight: 'normal', marginRight: 3 }}>USD</span>${totalPrice}</h2>
                                </Flex>
                                <Row>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Link to={paths.CHECKOUT}>
                                            <ButtonCheckoutStyled
                                                type={'button'}
                                                variant="outlined"
                                                size={'large'}
                                                fullWidth={true}
                                                onClick={checkoutHandle}>
                                                CHECKOUT
                                            </ButtonCheckoutStyled>
                                        </Link>
                                    </Col>
                                </Row>
                            </DivCheckoutStyled>
                        </FlexContainerStyled>
                }
            </Drawer>
        </Flex>
  );
};
