import React from 'react';
import { Button, Flex } from 'antd';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { delMapFromList } from '../../store/slices/oldMapSlice';
import { OldMapItemType } from '../../../../common/types';
import { colors } from '../../assets/constStyles';

const ButtonStyled = styled(Button)`
  width: 100%;
  background-color: transparent;
  border: none;
  color: ${colors.colorTextDark};
  text-decoration: underline;
  font-size: small;

  &:hover{
   background-color: transparent !important;
  }
`;

interface IButtonRemove {
  item: OldMapItemType
}

export const ButtonRemove: React.FC<IButtonRemove> = ({ item }) => {
  const dispatch = useDispatch<AppDispatch>();

  const delItemFromList = (oneItem: OldMapItemType) => {
    dispatch(delMapFromList(oneItem));
  };

  return (
      <Flex>
        <ButtonStyled
            variant='text'
            size={'large'}
            onClick={() => delItemFromList(item)}>
          Remove
        </ButtonStyled>
      </Flex>
  );
};
