export const paths = {
  MAIN: '/',
  STORE: '/store',
  STORE_ITEM: '/store',
  CHECKOUT: '/checkout',
  LIST_SERVICES: '/list_services',
  LOGIN: '/login',
  USER_REQUEST: '/user_request',
  ORDER: '/order',
};
