import * as React from 'react';
import { DateTime } from 'luxon';
import Cookies from 'js-cookie';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { DivMinWidth } from '../App';

export const LoginPage: React.FC = () => {
  Cookies.set('COOKIE_ID_TOKEN', 'aaa');
  const newVar: AuthenticationResultType = {};
  console.log(newVar);

  return (
        <div style={{ position: 'relative' }}>
            <DivMinWidth>
                Login Page
                <p>{DateTime.now().toFormat('yyyy-MM-dd')}</p>
            </DivMinWidth>
        </div>
  );
};
