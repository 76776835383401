import * as React from 'react';
import styled from 'styled-components';
import { Card, CardContent } from '@mui/material';
import { Flex, Image } from 'antd';
import { FlexStyled } from './StoreItemPage';
import { colors } from '../../assets/constStyles';

const DivStyled = styled.div`
  min-width: 350px;
  width: 30%;
  display: flex;
  flex-direction: column;
`;

const CardStyled = styled(Card)`
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(145, 158, 171, 0.3) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  background-color: ${colors.colorGrey} !important;

  &:hover {
    transform: scale(1.01);
    transition: all 0.1s ease-in 0s;
  }
`;

const TextCaptionStyled = styled.p`
  text-align: center;
  line-height: 1.5em;
  font-size: 1.5em;
  font-weight: 600;
`;

const TextSubCaptionStyled = styled.p`
  font-size: 1.1em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
`;

const TextDescStyled = styled.p`
text-align: justify;
`;

export const StoreCardItemComingSoon = () => (
        <DivStyled style={{ backgroundColor: colors.colorGrey }}>
            <CardStyled variant="outlined" sx={{
              border: 'none', display: 'flex', flexDirection: 'column', flexGrow: 1,
            }}>
                <Flex justify={'center'}>
                    <Image src={'./img/moreMapsSoon.png'} height={200} width={200} preview={false} loading="lazy"/>
                </Flex>

                <CardContent style={{ flexGrow: 1 }}>
                    <TextCaptionStyled>More Products</TextCaptionStyled>
                    <TextCaptionStyled>Coming Soon!</TextCaptionStyled>
                    <TextSubCaptionStyled>You've reached the end — for now. </TextSubCaptionStyled>
                    <TextDescStyled>The site has just launched with a small selection of products, but the full collection is being added gradually.
                        We're updating regularly—check back to explore the rest.</TextDescStyled>
                        <br/>
                    <div style={{ borderTop: `1px solid ${colors.colorGreen2}`, height: 0, margin: '0px 20px' }}></div>
                    <FlexStyled vertical>
                        <h3 style={{ fontWeight: 600 }}>Looking for something specific?</h3>
                        <p style={{ margin: '10px 0' }}>Feel free to reach out through our contact form — we’re happy to help.</p>
                    </FlexStyled>
                </CardContent>
            </CardStyled>
        </DivStyled>
);
