import React, { useCallback, useEffect, useState } from 'react';
import {
  Col, Flex, Form, Input, Row, Spin,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { MaskedInput } from 'antd-mask-input';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppDispatch, RootState } from '../store';
import { setterApp } from '../store/slices/oldMapSlice';
import { ContactUserType, GooglePlaceType, OrderDetailType } from '../../../common/types';
import { PlacesAutocomplete } from './form/PlacesAutocomplete';

const H2Styled = styled.h2`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const DivShippingStyled = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  background-color: #F6F6F6;
  padding: 10px;
`;

interface IDeliveryInfo{

}

export const DeliveryInfo: React.FC<IDeliveryInfo> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [spinModalRegistration, setSpinModalRegistration] = useState(false);
  const [form] = Form.useForm();
  const [serviceTempAddress, setServiceTempAddress] = useState<GooglePlaceType>();
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const orderDetail = useSelector<RootState, OrderDetailType>((state) => state.oldMap.orderDetail);

  useEffect(() => {
    if (!serviceTempAddress) {
      form.setFieldsValue({
        city: '',
        street: '',
        cityState: '',
        postalCode: '',
      });
      return;
    }

    form.setFieldsValue({
      city: `${serviceTempAddress?.address.locality}`,
      street: `${serviceTempAddress?.address.streetNumber} ${serviceTempAddress?.address.route}`,
      cityState: `${serviceTempAddress?.address.locality}, ${serviceTempAddress?.address.administrativeAreaLevel1}`,
      state: serviceTempAddress?.address.administrativeAreaLevel1,
      postalCode: serviceTempAddress?.address.postalCode,
    });
  }, [serviceTempAddress, form]);

  const onFinish = useCallback(async (values: ContactUserType) => {
    setSpinModalRegistration(true);

    try {
      dispatch(setterApp({
        orderDetail: {
          ...orderDetail,
          userData: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            address: values.address,
            street: values.street,
            apartment: values.apartment,
            city: values.city,
            postalCode: values.postalCode,
            cityState: values.cityState,
            state: values.state,
          },
        },
      }));
      dispatch(setterApp({ deliveryInfoFilled: true }));
    } catch (e: any) {
      console.log(e.response.data.message);
    }
    setSpinModalRegistration(false);
    setDisabledButton(true);
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return <>
        <H2Styled>1. Delivery Information</H2Styled>
        <h4 style={{ marginBottom: 10, fontWeight: 'bold' }}>Ship to:</h4>
        <Spin spinning={spinModalRegistration} tip={'Please wait'} style={{ width: '100%' }}>
            <Form
                form={form}
                name="checkoutData"
                style={{ width: '100%' }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size={'large'}
            >
                <Row gutter={10}>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item<ContactUserType>
                            name="firstName"
                            rules={[{ required: true, message: 'Please fill out this field.' }]}
                        >
                            <Input placeholder={'First Name'}/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item<ContactUserType>
                        name="lastName"
                        rules={[{ required: true, message: 'Please fill out this field.' }]}
                    >
                        <Input placeholder={'Last Name'}/>
                    </Form.Item>
                    </Col>
                </Row>
                <Form.Item<ContactUserType> name="street">
                    <PlacesAutocomplete
                        setServiceTempAddress={setServiceTempAddress}
                        serviceTempAddress={serviceTempAddress}
                    />
                </Form.Item>
                <Form.Item<ContactUserType>
                    name="apartment"
                >
                    <Input placeholder={'Apartment, suite, etc. (optional)'}/>
                </Form.Item>
                <Row gutter={10}>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item<ContactUserType>
                            name="street"
                            rules={[{ required: true, message: 'Please fill out this field.' }]}
                        >
                            <Input placeholder={'Street'} disabled/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item<ContactUserType>
                            name="cityState"
                            rules={[{ required: true, message: 'Please fill out this field.' }]}
                        >
                            <Input placeholder={'State'} disabled/>
                        </Form.Item>
                        <div style={{ display: 'none' }}>
                            <Form.Item<ContactUserType>
                                name="state"
                            >
                                <Input placeholder={'State'} disabled/>
                            </Form.Item>
                            <Form.Item<ContactUserType>
                                name="city"
                            >
                                <Input placeholder={'City'} disabled/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={4} lg={4} xl={4}>
                        <Form.Item<ContactUserType>
                            name="postalCode"
                            rules={[{ required: true, message: 'Please fill out this field.' }]}
                        >
                            <Input placeholder={'Postal Code'} disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col className="gutter-row" xs={24} sm={24} md={16} lg={16} xl={16}>
                        <Form.Item<ContactUserType>
                            name="email"
                            rules={[{ required: true, message: 'Please fill out this field.' },
                              {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                              }]}
                        >
                            <Input placeholder={'Email address'}/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item<ContactUserType>
                        name="phone"
                        key={uuidv4()}
                        rules={[
                          { required: true, message: 'Please fill out this field.' },
                        ]}
                    >
                        <MaskedInput
                            required={true}
                            size={'large'}
                            mask={'+1(000)000-0000'}
                            type={'tel'}
                        />
                    </Form.Item>
                    </Col>
                </Row>

                <Form.Item style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Button variant="contained" type={'submit'} aria-label={'Save'} color="success" disabled={disabledButton}>Next step</Button>
                </Form.Item>
            </Form>
        </Spin>
      <h4 style={{ marginBottom: 10, fontWeight: 'bold' }}>Shipping information:</h4>
      <DivShippingStyled>
          <Flex justify={'space-between'}>
              <h4>United States Postal Service</h4>
              <p>${orderDetail.maxShipCost}</p>
          </Flex>
          <div style={{ color: '#686868' }}>
              <p>5 to 9 business days</p>
              <p>Inclusive of all shipping, duties, taxes and fees</p>
          </div>
      </DivShippingStyled>
    </>;
};
