import * as React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { DivMinWidth } from '../App';
import { CheckoutUserData } from '../components/CheckoutUserData';
import { CheckoutCart } from '../components/CheckoutCart';

const FlexCheckoutPageStyled = styled.div`
  width: 100%;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const CheckoutPage: React.FC = () => (
        <div style={{ position: 'relative' }}>
            <DivMinWidth>
                <FlexCheckoutPageStyled>
                    <Row gutter={20}>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={14} xl={14} style={{ backgroundColor: '#FFFFFF' }}>
                            <CheckoutUserData />
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={10} xl={10} style={{ backgroundColor: '#F5F5F5', borderLeft: '1px solid #DEDEDE' }}>
                            <CheckoutCart />
                        </Col>
                    </Row>
                </FlexCheckoutPageStyled>
            </DivMinWidth>
        </div>
);
