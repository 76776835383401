import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Col, Flex, GetProps, Input, Pagination, PaginationProps, Row, Select, Spin,
} from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import { LoadingOutlined } from '@ant-design/icons';
import { StoreCardItem } from './StoreCardItem';
import { AppDispatch, RootState } from '../../store';
import { fetchListOldMaps } from '../../store/slices/oldMapSlice';
import { StoreOldMapIteType } from '../../../../common/types';
import { StoreCardItemComingSoon } from './StoreCardItemComingSoon';

type SearchProps = GetProps<typeof Input.Search>;

const FlexStyled = styled(Flex)`
  padding: 40px;
  align-items: stretch;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const FlexPaginatorStyled = styled(Flex)`
  padding: 0 40px 40px 40px;

  @media (max-width: 768px) {
    padding: 0 20px 20px 20px;
  }
`;

export const StoreItems: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const listOldMap = useSelector<RootState, StoreOldMapIteType>((state) => state.oldMap.oldMapList);
  const [currentPage, setCurrentPage] = useState(1);
  const [newPageSize, setNewPageSize] = useState(9);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortType, setSortType] = useState('Featured');
  const oldMapLoader = useSelector<RootState, boolean>((state) => state.oldMap.oldMapLoader);
  const filteredData = Object.values(listOldMap).filter((item) => item.price > 0 && (item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.description.toLowerCase().includes(searchTerm.toLowerCase())));
  const startIndex = (currentPage - 1) * newPageSize;
  const endIndex = startIndex + newPageSize;

  useEffect(() => {
    (async () => {
      try {
        dispatch(fetchListOldMaps());
      } catch (e) {
        console.log(e);
      }
    })();
  }, [dispatch]);

  useEffect(() => {

  }, []);

  const sortedData = [...filteredData].sort((a, b) => {
    switch (sortType) {
      case 'AZ':
        return a.name.localeCompare(b.name);
      case 'ZA':
        return b.name.localeCompare(a.name);
      case 'LowToHigh':
        return a.price - b.price;
      case 'HighToLow':
        return b.price - a.price;
      default:
        return 0; // Featured — оригинальный порядок
    }
  });

  const paginatedData = sortedData.slice(startIndex, endIndex);

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setCurrentPage(current);
    setNewPageSize(pageSize);
  };

  const handleSearchChange = (e: any) => {
    const { value } = e.target;
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handleSortChange = (value: string) => {
    setSortType(value);
    setCurrentPage(1);
  };

  const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`;

  return (
        <Spin spinning={oldMapLoader} style={{ minHeight: 400 }}
              indicator={<LoadingOutlined style={{ fontSize: 100 }} spin/>}>
            <Row style={{ padding: '0 40px' }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Search
                        placeholder="What map are you looking for?"
                        size={'large'}
                        onSearch={onSearch}
                        onChange={handleSearchChange}
                        enterButton
                        allowClear
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ justifyItems: 'right' }}>
                    <Flex gap={10} align={'center'}>
                        {/* <FilterList /> */}
                        <Flex gap={10} align={'center'}>
                            <p>Sort by</p>
                            <Select
                                defaultValue="Featured"
                                style={{ width: 180 }}
                                onChange={handleSortChange}
                                options={[
                                  { value: 'Featured', label: 'Featured' },
                                  { value: 'AZ', label: 'Alphabetically, A-Z' },
                                  { value: 'ZA', label: 'Alphabetically, Z-A' },
                                  { value: 'LowToHigh', label: 'Price, low to high' },
                                  { value: 'HighToLow', label: 'Price, high to low' },
                                ]}
                            />
                        </Flex>
                    </Flex>
                </Col>
            </Row>
            <FlexStyled justify={'space-between'} align={'center'} wrap={'wrap'} gap={20}>
                {paginatedData.map((map) => <StoreCardItem key={map.id} dataMap={map}/>)}
                {currentPage === Math.ceil(Object.keys(listOldMap).length / newPageSize)
                    && <StoreCardItemComingSoon/>
                }
            </FlexStyled>
            <FlexPaginatorStyled justify={'center'}>
                <Pagination
                    showSizeChanger
                    onChange={onShowSizeChange}
                    onShowSizeChange={onShowSizeChange}
                    defaultCurrent={1}
                    total={filteredData.length}
                    showTotal={showTotal}
                    pageSizeOptions={[9, 18, 27]}
                    current={currentPage}
                    pageSize={newPageSize}
                />
            </FlexPaginatorStyled>
        </Spin>
  );
};
