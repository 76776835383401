import React from 'react';
import { Flex, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DivMinWidth } from '../App';
import { colors } from '../assets/constStyles';
import { AppDispatch, RootState } from '../store';
import { setterApp } from '../store/slices/oldMapSlice';

const MAIN_IMG = './img/mainPicComp.png';

export const ContentBody: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const counterMainImgClick = useSelector<RootState, number>((state) => state.oldMap.counterMainImgClick);

  const onClickToImage = () => {
    dispatch(setterApp({ counterMainImgClick: counterMainImgClick + 1 }));
  };

  return (
        <div style={{ position: 'relative' }}>
            <DivMinWidth style={{ zIndex: 10, paddingBottom: '100px' }}>
                <Flex align={'center'} vertical>
                    <Image src={MAIN_IMG} preview={false} loading="lazy" onClick={onClickToImage} width={'100%'} style={{ borderRadius: '16px' }}/>
                </Flex>
            </DivMinWidth>
            <div style={{
              width: '100%', height: '300px', backgroundColor: colors.colorFill, position: 'absolute', bottom: 0, zIndex: 1,
            }}>
            </div>
        </div>
  );
};
