import React from 'react';
import { Col, Image, Row } from 'antd';
import { OldMapItemType } from '../../../common/types';

interface IShopItem{
  item: OldMapItemType
}

export const ShopItemCheckout: React.FC<IShopItem> = ({ item }) => (
    <Row gutter={20}>
        <Col className="gutter-row" xs={4} sm={4} md={4} lg={4} xl={4}>
            <Image
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={`${process.env.REACT_APP_S3_STORAGE_URL_COMMON}${process.env.REACT_APP_BUCKET_COMMON_IMAGE}${item.link}`}
                loading="lazy"
                preview={false}
                wrapperStyle={{ height: '100%', paddingBottom: '20px' }}
            />
        </Col>
        <Col className="gutter-row" xs={16} sm={16} md={16} lg={16} xl={16}>
            <h3>1 x {item.name}</h3>
        </Col>
        <Col className="gutter-row" xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'right' }}>
            <h3 style={{ fontWeight: 'bolder' }}>${item.price}</h3>
        </Col>
    </Row>
);
