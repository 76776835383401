import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { PayPalButton2 } from './button/PayPalButton2';
import { RootState } from '../store';

const H2Styled = styled.h2`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

interface IPaymentInfo{
}

export const PaymentInfo: React.FC<IPaymentInfo> = () => {
  const finalPrice = useSelector<RootState, number>((state) => state.oldMap.orderDetail.finalPrice);
  const deliveryInfoFilled = useSelector<RootState, boolean>((state) => state.oldMap.deliveryInfoFilled);

  return <>
      <H2Styled>2. Payment</H2Styled>
      {
          deliveryInfoFilled
          && <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.9 }}
          >
              <h4 style={{ margin: '10px 0' }}>Choose method: </h4>
                <PayPalButton2 amount={String(finalPrice)}/>
              </motion.div>
      }
    </>;
};
