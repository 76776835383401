import * as React from 'react';
import { AboutUs } from '../components/section/AboutUs';
import { ContactUs } from '../components/section/ContactUs';
import { ContentMain } from '../components/layout/ContentMain';

export const MainPage: React.FC = () => (
    <div style={{ position: 'relative' }}>
        <ContentMain />
        <AboutUs />
        <ContactUs />
    </div>
);
