import * as React from 'react';
import styled from 'styled-components';
import { Card, CardMedia } from '@mui/material';
import { Flex, Tag } from 'antd';
import { OldMapItemType } from '../../../../common/types';
import { colors } from '../../assets/constStyles';
import { ButtonToCart } from '../../components/button/ButtonToCart';

const CardStyled = styled(Card)`
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(145, 158, 171, 0.3) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;

  &:hover {
    transform: scale(1.01);
    transition: all 0.1s ease-in 0s;
  }
`;

const TextStyled = styled.p`
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2em;
  min-height: calc(2 * 1.2em);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
`;

interface IStoreOneItem {
  dataMap: OldMapItemType
}

export const StoreCardItemMini: React.FC<IStoreOneItem> = ({ dataMap }) => (
            <CardStyled variant="outlined" sx={{
              border: 'none',
              display: 'flex',
              flexDirection: 'column',
            }}>
                    <CardMedia
                        component="img"
                        height="150"
                        image={`${process.env.REACT_APP_S3_STORAGE_URL_COMMON}${process.env.REACT_APP_BUCKET_COMMON_IMAGE}${dataMap.link}`}
                        alt="chart"
                    />
                    <div style={{ padding: '10px' }}>
                        <TextStyled>
                            {dataMap.name}
                        </TextStyled>
                        <Flex style={{ padding: '10px 0px' }}>
                            <Tag
                                style={{ fontSize: '1.2em', padding: '5px' }}
                                color={colors.colorGreen}
                            >
                                {`${dataMap.size.width} x ${dataMap.size.height}`}
                            </Tag>
                        </Flex>
                        <Flex style={{ paddingBottom: '20px' }}>
                            <p style={{ color: colors.colorTextDark, fontSize: '1.2em', fontWeight: 'bolder' }}>
                                ${dataMap.price}
                            </p>
                        </Flex>
                        <ButtonToCart text={'Add'} item={dataMap}/>
                    </div>
            </CardStyled>
);
