import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Button, Col, Flex, Modal, Row, Spin, Tag,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { HelmetProvider } from 'react-helmet-async';
import { AppDispatch, RootState } from '../../store';
import { setterApp } from '../../store/slices/oldMapSlice';
import { DivMinWidth } from '../../App';
import { H1Styled, H4Styled } from '../../components/ContentHeader';
import { colors } from '../../assets/constStyles';
import { ButtonToCart } from '../../components/button/ButtonToCart';
import { OpenLayerMap } from '../../components/OpenLayerMap';
import { RequestSizeForm } from '../../components/form/RequestSizeForm';
import { OldMapItemType, StoreOldMapIteType } from '../../../../common/types';
import { paths } from '../../consts';
import { FlexTagStyled, TagSizeStyled } from './StoreCardItem';

export const DivStyled = styled.div`
  padding: 0px 40px 20px 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const H2Styled = styled.h2`
  color: ${colors.colorTextDark};
  font-size: 24px;
`;

export const FlexStyled = styled(Flex)`
  background-color: ${colors.colorGrey}; 
  border-radius: 4px;
  padding: 20px 10px;
`;
const FlexHeaderStyled = styled(Flex)`
  width: 100%;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ColStyled = styled(Col)`
  padding: 0 10px;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const StoreItemPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const urlParams = useParams();
  const oldMapItem = useSelector<RootState, OldMapItemType | null>((state) => state.oldMap.oldMapItem);
  const oldMapList = useSelector<RootState, StoreOldMapIteType>((state) => state.oldMap.oldMapList);
  const oldMapLoader = useSelector<RootState, boolean>((state) => state.oldMap.oldMapLoader);
  const canonicalUrl = `https://oldcharts.com/store/${oldMapItem?.id}`;
  const [open, setOpen] = useState(false);
  const refButtonForm = useRef<HTMLInputElement>(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOk = () => {
    refButtonForm.current?.click();
  };

  const handleForm = (isOk: boolean) => {
    if (isOk) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (oldMapItem) {
      const [currentItem] = Object.values(oldMapList).filter((map) => map.id === urlParams.id);
      dispatch(setterApp({ oldMapItem: currentItem }));
    } else {
      (async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_REST_API}${paths.LIST_SERVICES}`);
        const currentItem = data[urlParams.id!];
        dispatch(setterApp({ oldMapItem: currentItem }));
      })();
    }
  }, [urlParams]);

  const colorTag = oldMapItem && oldMapItem.type === 'Original' ? colors.colorOriginal : colors.colorReproduction;

  return (<>
      <HelmetProvider>
        <title>{`${oldMapItem?.name} | Review`}</title>
        <meta name="description" content={oldMapItem?.description}/>
        <link rel="canonical" href={canonicalUrl}/>
      </HelmetProvider>
      <div style={{ position: 'relative' }}>
        <DivMinWidth>
          <FlexHeaderStyled vertical>
            <H4Styled>Our store</H4Styled>
            <H1Styled>Explore our offerings</H1Styled>
          </FlexHeaderStyled>
          <DivStyled>
            {
              !oldMapItem
                ? null
                : <Spin spinning={oldMapLoader} tip={'Please wait ...'} style={{ minHeight: 400 }}>
                      <Row>
                        <ColStyled xs={24} sm={24} md={12} lg={12} xl={12} >
                            <div style={{ width: '100%', height: 500 }}>
                              <OpenLayerMap image={oldMapItem} />
                            </div>
                        </ColStyled>
                        <ColStyled xs={24} sm={24} md={12} lg={12} xl={12}>
                          <Flex justify={'flex-end'}>
                            <span>ItemID: {oldMapItem.id}</span>
                          </Flex>
                          <div style={{ padding: '20px 0px' }}>
                            <H2Styled style={{ marginBottom: 20 }}>{oldMapItem.name}</H2Styled>
                            <Tag color={colorTag}>{oldMapItem.type}</Tag>
                            {
                              oldMapItem.state && <Tag color={colors.colorState}>{oldMapItem.state}</Tag>
                            }
                            <h3 style={{ fontWeight: 500, marginTop: '20px', textAlign: 'justify' }}>{oldMapItem.description}</h3>
                            <FlexTagStyled>
                              <TagSizeStyled
                                  color={colors.colorGreen}
                              >
                                {`${oldMapItem.size.width} x ${oldMapItem.size.height}`}
                              </TagSizeStyled>
                            </FlexTagStyled>
                            <Flex style={{ paddingBottom: '20px' }}>
                              <h2 style={{ color: colors.colorTextDark }}>
                                ${oldMapItem.price}
                              </h2>
                            </Flex>
                            <ButtonToCart item={oldMapItem}/>
                          </div>
                          <FlexStyled vertical>
                              <h3 style={{ fontWeight: 600 }}>Need a Different Size? Let us know!</h3>
                              <p style={{ margin: '10px 0' }}>Fill out the form with your preferred dimensions, and we’ll get back to you with a solution.</p>
                              <Flex justify={'left'}>
                                <Button
                                    type="primary"
                                    aria-label={'Send request'}
                                    onClick={handleOpen}
                                    style={{
                                      background: colors.colorTextDark,
                                      border: 'none',
                                      color: 'white',
                                    }}
                                >
                                  Send Us Message
                                </Button>
                              </Flex>
                          </FlexStyled>
                        </ColStyled>
                      </Row>
                  </Spin>
            }
          </DivStyled>
        </DivMinWidth>
        {
          oldMapItem
            && <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleClose}
                okButtonProps={{ size: 'large', style: { backgroundColor: colors.colorTextDark } }}
                cancelButtonProps={{ size: 'large', className: 'custom-cancel-btn' }}
                okText={'Send'}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                      <CancelBtn />
                      <OkBtn/>
                    </>
                )}
            >
              <RequestSizeForm refButton={refButtonForm} handleForm={handleForm} item={oldMapItem}/>
            </Modal>
        }
      </div>
    </>
  );
};
