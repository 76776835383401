import React from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { LocationOn, Phone, Schedule } from '@mui/icons-material';
import { H1Styled } from '../ContentHeader';
import { colors } from '../../assets/constStyles';

const FlexStyled = styled(Flex)`
  padding: 20px;
  font-size: 1.5em;
  color: ${colors.colorTextDark};
`;

const PCaptionStyled = styled.p`
    font-weight: 700;
  `;

const PTextStyled = styled.p`
  text-align: center;
  margin: 0;
`;

const FlexMainStyled = styled(Flex)`
  width: 100%;
  padding: 100px 40px 40px 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const ContactUsLocation: React.FC = () => (
    <FlexMainStyled vertical id={'location'}>
        <H1Styled style={{ textAlign: 'center' }}>Better yet, see us in person!</H1Styled>
        <h4 style={{ textAlign: 'center' }}>Please call before coming to our store <br /> as we attend many exhibits and shows.</h4>
        <div>
            <FlexStyled vertical justify={'center'} align={'center'}>
                <Flex align={'center'} gap={10}>
                    <LocationOn/>
                    <PCaptionStyled>Location</PCaptionStyled>
                </Flex>
                <PTextStyled>195 Washington Street, Hanover, Massachusetts 02339, United States</PTextStyled>
            </FlexStyled>
            <FlexStyled vertical justify={'center'} align={'center'}>
                <Flex align={'center'} gap={10}>
                    <Phone />
                    <PCaptionStyled>Phone</PCaptionStyled>
                </Flex>
                <PTextStyled>781-470-0213</PTextStyled>
            </FlexStyled>
            <FlexStyled vertical justify={'center'} align={'center'}>
                <Flex align={'center'} gap={10}>
                    <Schedule />
                <PCaptionStyled>Store Hours</PCaptionStyled>
                </Flex>
                <PTextStyled>Wednesday - Sunday</PTextStyled>
                <PTextStyled>11:00 AM - 5:00 PM</PTextStyled>
            </FlexStyled>
        </div>
    </FlexMainStyled>
);
